// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lista-objetos-reporte {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    width: 97%;
    margin-top: 31px;
}

.lista-objetos-reporte table {
    border-collapse: collapse;
    width: 100%;
}

.lista-objetos-reporte th, .lista-objetos-reporte td, .lista-objetos-reporte tr{
    padding: 20px 10px 20px 10px;
    text-align: left;
    border-bottom: 1px solid #051816;
}

.lista-objetos-reporte th{
    color:#051816;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    font-size: 16pt;
}

/*RESPONSIVO*/


@media only screen and (max-width: 779px) {
    .lista-objetos-reporte {
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        width: 100%;
        margin-top: 31px;
    }
    .lista-objetos-reporte th {
        color: #051816;
        font-weight: 700;
        font-family: "Raleway", sans-serif;
        font-size: 12pt;
    }
}

@media only screen and (max-width: 360px) {
    .lista-objetos-reporte th {
        color: #051816;
        font-weight: 700;
        font-family: "Raleway", sans-serif;
        font-size: 9pt;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Style/Report.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,kCAAkC;IAClC,eAAe;AACnB;;AAEA,aAAa;;;AAGb;IACI;QACI,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,WAAW;QACX,gBAAgB;IACpB;IACA;QACI,cAAc;QACd,gBAAgB;QAChB,kCAAkC;QAClC,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,gBAAgB;QAChB,kCAAkC;QAClC,cAAc;IAClB;AACJ","sourcesContent":[".lista-objetos-reporte {\r\n    padding: 20px;\r\n    background-color: white;\r\n    border-radius: 20px;\r\n    width: 97%;\r\n    margin-top: 31px;\r\n}\r\n\r\n.lista-objetos-reporte table {\r\n    border-collapse: collapse;\r\n    width: 100%;\r\n}\r\n\r\n.lista-objetos-reporte th, .lista-objetos-reporte td, .lista-objetos-reporte tr{\r\n    padding: 20px 10px 20px 10px;\r\n    text-align: left;\r\n    border-bottom: 1px solid #051816;\r\n}\r\n\r\n.lista-objetos-reporte th{\r\n    color:#051816;\r\n    font-weight: 700;\r\n    font-family: \"Raleway\", sans-serif;\r\n    font-size: 16pt;\r\n}\r\n\r\n/*RESPONSIVO*/\r\n\r\n\r\n@media only screen and (max-width: 779px) {\r\n    .lista-objetos-reporte {\r\n        padding: 20px;\r\n        background-color: white;\r\n        border-radius: 20px;\r\n        width: 100%;\r\n        margin-top: 31px;\r\n    }\r\n    .lista-objetos-reporte th {\r\n        color: #051816;\r\n        font-weight: 700;\r\n        font-family: \"Raleway\", sans-serif;\r\n        font-size: 12pt;\r\n    }\r\n}\r\n\r\n@media only screen and (max-width: 360px) {\r\n    .lista-objetos-reporte th {\r\n        color: #051816;\r\n        font-weight: 700;\r\n        font-family: \"Raleway\", sans-serif;\r\n        font-size: 9pt;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
