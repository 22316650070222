// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.lista-objetos-encontrado {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    width: 97%;
    margin-top: 31px;
}
.lista-objetos-encontrado table {
    border-collapse: collapse;
    width: 100%;
}

.lista-objetos-encontrado th, .lista-objetos-encontrado td {
    padding: 20px 10px 20px 10px;
    text-align: left;
    border-bottom: 1px solid #051816;
}

.lista-objetos-encontrado th{
    color:#051816;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    font-size: 16pt;
}

/*RESPONSIVIDADE*/
@media only screen and (max-width: 546px) {
    .lista-objetos-encontrado th {
        color: #051816;
        font-weight: 700;
        font-family: "Raleway", sans-serif;
        font-size: 12pt;
    }
}

@media only screen and (max-width: 382px) {
.lista-objetos-encontrado {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    margin-top: 31px;
}
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Style/Found.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,kCAAkC;IAClC,eAAe;AACnB;;AAEA,iBAAiB;AACjB;IACI;QACI,cAAc;QACd,gBAAgB;QAChB,kCAAkC;QAClC,eAAe;IACnB;AACJ;;AAEA;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;AACpB;AACA","sourcesContent":["\r\n.lista-objetos-encontrado {\r\n    padding: 20px;\r\n    background-color: white;\r\n    border-radius: 20px;\r\n    width: 97%;\r\n    margin-top: 31px;\r\n}\r\n.lista-objetos-encontrado table {\r\n    border-collapse: collapse;\r\n    width: 100%;\r\n}\r\n\r\n.lista-objetos-encontrado th, .lista-objetos-encontrado td {\r\n    padding: 20px 10px 20px 10px;\r\n    text-align: left;\r\n    border-bottom: 1px solid #051816;\r\n}\r\n\r\n.lista-objetos-encontrado th{\r\n    color:#051816;\r\n    font-weight: 700;\r\n    font-family: \"Raleway\", sans-serif;\r\n    font-size: 16pt;\r\n}\r\n\r\n/*RESPONSIVIDADE*/\r\n@media only screen and (max-width: 546px) {\r\n    .lista-objetos-encontrado th {\r\n        color: #051816;\r\n        font-weight: 700;\r\n        font-family: \"Raleway\", sans-serif;\r\n        font-size: 12pt;\r\n    }\r\n}\r\n\r\n@media only screen and (max-width: 382px) {\r\n.lista-objetos-encontrado {\r\n    padding: 20px;\r\n    background-color: white;\r\n    border-radius: 20px;\r\n    width: 100%;\r\n    margin-top: 31px;\r\n}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
